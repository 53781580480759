// PostMessageContext.js
import Router from "next/router";
import React from "react";
const PostMessageContext = React.createContext();
import {closeCpModal} from '../../store/centralPaymentSlice'
import { handleDepositClick } from "../CentralPayment/CentralPaymentModal/CentralPaymentUtils";
import { connect } from "react-redux";
// import {setIsLogout} from '$LIB/utils/util'

class PostMessageProvider extends React.Component {

  componentDidMount() {
    window.addEventListener('message', this.handlePostMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handlePostMessage);
  }

  goUserCenter(key) {
        if (~global.location.pathname.indexOf('/cn/usercenter')) {
            if (key === 'vip') {
                Router.push('/cn/vip');
            } else {
                global.goPage(key, true);
            }
        } else {
            if (key === 'vip') {
                Router.push('/cn/vip');
            } else {
                sessionStorage.setItem('userCenterPageKey', key);
                Router.push('/cn/usercenter');
            }
        }
    }

  getUrlParams = () => {
      if (typeof window !== 'undefined') {
        const searchParams = new URLSearchParams(window.location.search); // 獲取 URL 查詢字串
        const params = {};
        for (let [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        return params;
      }
      return {};
  };

  closePopup = () => {
    const { closeCentralPaymentModal, useCentralPayment } = this.props;
    console.log("Closing popup, useCentralPayment:", useCentralPayment);
    if (useCentralPayment) {
      this.props.dispatch(closeCpModal());
    } else {
      Router.push("/");
    }
  };

  handlePostMessage = (event) => {
    // 先確保 data 存在
    const { data } = event;
    if (!data) return;

    // 如果是 React DevTools 的消息則忽略
    if (
      data.source === 'react-devtools-content-script' || 
      data.source === 'react-devtools-bridge' ||
      typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'payload')  // 增加這個判斷
    ) {
      return;
    }

    console.log('[PostMessage] Received message:', data);  // 加上標識符

    // 處理其他消息
    switch (data.event) {
      case 'addBank':
        this.closePopup();
        Router.push('/cn/bankCard')
        break;
      case 'goBackToWallet':
        this.closePopup();
        break;
      case 'closeIframe':
        this.closePopup();
        break;
      case 'closePopup':
        this.closePopup();
        break;
      case 'goToHistory':
        this.closePopup();
        Router.push('/cn/history')
        break
      case 'goToHistoryW':
          const query = this.getUrlParams();
          this.closePopup();
          if (query.type == 'withdrawal') {
              window.location.reload();
          } else {
              Router.push('/cn/history/?type=withdrawal');
          }
          break;
      case 'goToHistoryOther':
        Router.push('/cn/history/?type=other')
        setTimeout(() => {
          window.location.reload();
        } , 1000)
        break
      case 'closeHistoryPage':
        Router.back()
        break
      case 'goUserInfo':
        this.closePopup();
        this.goUserCenter('userinfo')
        break;
      case 'goToKycPage':
        this.closePopup();
        this.goUserCenter('uploadFiles');
        break;
      case 'NetworkError':
        Router.push('/cn/')
        // setIsLogout()
        break;
      case 'goToDeposit':
        this.closePopup();
        setTimeout(() => {
          handleDepositClick(this.props.dispatch, this.props.useCentralPayment);
        }, 500);
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <PostMessageContext.Provider value={this.handlePostMessage}>
        {this.props.children}
      </PostMessageContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  useCentralPayment: state.centralPayment.useCentralPayment,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PostMessageProvider);

// 添加 Consumer Hook
export const usePostMessage = () => {
  return React.useContext(PostMessageContext);
};