import { createSlice } from "@reduxjs/toolkit";

// 定义 slice
const campaignSlice = createSlice({
    name: "campaign",
    initialState: {
        UIIsLoading: false,
        AssignedClaims: null,
        List: null,
    },
    reducers: {
        setUIIsLoading: (state, action) => {
            state.UIIsLoading = action.payload;
        },
        setAssignedClaims: (state, action) => {
            state.AssignedClaims = action.payload
        },
        setList: (state, action) => {
            state.List = action.payload
        },
    },
});

// 导出 store
export default campaignSlice;

// 获取 actions 对象
export const campaignActions = campaignSlice.actions;