import { createSlice } from "@reduxjs/toolkit";

// 定义 slice
const bonusSlice = createSlice({
    name: "bonus",
    initialState: {
        UIIsLoading: false,
        v2: null,
        Progress: null,
        Applications: null,
        Calculate: null,
        Eligible: null,
        AppliedHistory: null,
        ApplicationsByDate: null,
        Cancellation: null,
        CancellationEligibility: null,
    },
    reducers: {
        setUIIsLoading: (state, action) => {
            state.UIIsLoading = action.payload;
        },
        setV2: (state, action) => {
            state.v2 = action.payload
        },
        setProgress: (state, action) => {
            state.Progress = action.payload
        },
        setApplications: (state, action) => {
            state.Applications = action.payload
        },
        setCalculate: (state, action) => {
            state.Calculate = action.payload
        },
        setEligible: (state, action) => {
            state.Eligible = action.payload
        },
        setAppliedHistory: (state, action) => {
            state.AppliedHistory = action.payload
        },
        setApplicationsByDate: (state, action) => {
            state.ApplicationsByDate = action.payload
        },
        setCancellation: (state, action) => {
            state.Cancellation = action.payload
        },
        setCancellationEligibility: (state, action) => {
            state.CancellationEligibility = action.payload
        },
    },
});

// 导出 store
export default bonusSlice;

// 获取 actions 对象
export const bonusActions = bonusSlice.actions;
