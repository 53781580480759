import { createSlice } from "@reduxjs/toolkit";

// 定义 slice
const balanceSlice = createSlice({
    name: "balance",
    initialState: {
        UIIsLoading: false,
        v2: null,
        WalletGroup: null,
    },
    reducers: {
        setUIIsLoading: (state, action) => {
            state.UIIsLoading = action.payload;
        },
        setV2: (state, action) => {
            state.v2 = action.payload
        },
        setWalletGroup: (state, action) => {
            state.WalletGroup = action.payload
        }
    },
});

// 导出 store
export default balanceSlice;

// 获取 actions 对象
export const balanceActions = balanceSlice.actions;