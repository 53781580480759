import Router from "next/router";
import { openCpModal } from "../../../store/centralPaymentSlice";

export const handleDepositClick = (dispatch, useCentralPayment) => {
  if (useCentralPayment) {
    dispatch(openCpModal('deposit'));
  } else {
    Router.push('/deposit');
  }
};

export const handleWithdrawalClick = (dispatch, useCentralPayment) => {
  if (useCentralPayment) {
    dispatch(openCpModal('withdrawal'));
  } else {
    Router.push('/withdrawal');
  }
};