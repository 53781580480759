import { configureStore, createSlice } from "@reduxjs/toolkit";

const DEFAULT_AFFILIATE_URL = 'https://www.h32lucky.com/zh-cn';

// 定义 slice
const mainSiteConfigSlice = createSlice({
    name: "mainSiteConfig",
    initialState: { affiliateUrl: DEFAULT_AFFILIATE_URL },
    reducers: {
        setAffiliateUrl: (state, action) => {
            const affiliateUrl = action.payload || DEFAULT_AFFILIATE_URL;
            return { ...state, affiliateUrl };
        },
    },
});

// 导出 store
export default mainSiteConfigSlice;

// 获取 actions 对象
export const mainSiteConfigActions = mainSiteConfigSlice.actions;
