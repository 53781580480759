import { createSlice } from '@reduxjs/toolkit';

// 初始狀態
const initialState = {
  useCentralPayment: false, // 預設
  modalOpen: false,
  modalType: null,
};

// 創建 slice
const centralPaymentSlice = createSlice({
  name: 'centralPayment',
  initialState,
  reducers: {
    // 定義 reducer 和對應的 action
    toggleCentralPayment: (state, action) => {
      // action.payload = true/false
      state.useCentralPayment = action.payload;
    },
    openCpModal: (state, action) => {
      state.modalOpen = true;
      state.modalType = action.payload;
    },
    closeCpModal: (state) => {
      state.modalOpen = false;
      state.modalType = null;
    }
  }
});

// 自動生成的 action creator
export const { toggleCentralPayment, openCpModal, closeCpModal } = centralPaymentSlice.actions;

// 如果需要在其他文件中直接使用 action type
export const TOGGLE_CENTRAL_PAYMENT = 'centralPayment/toggleCentralPayment';
export const OPEN_CP_MODAL = 'centralPayment/openCpModal';
export const CLOSE_CP_MODAL = 'centralPayment/closeCpModal';

// 導出 reducer
export default centralPaymentSlice;